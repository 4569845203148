<template>
  <div class="main_content align-items-center" style="min-height: 80vh">
    <div class="row justify-content-center">
      <div class="row justify-content-between mb_54">
        <div class="col-md-12 col-lg-6 top_heading">
          <div class="heading">
            <h2 style="color: #39353d" v-if="ergo_eval">Ergo Evaluation</h2>
            <h2 style="color: #39353d" v-else>Self-Assessments</h2>
            <!-- <h3 style="color: #39353d">Custom Search Dashboard</h3> -->
          </div>
          <div class="program_list hover_links mb-lg-0 mb-3">
            <ul class="list-unstyled d-flex flex-wrap mb-0">
              <li>
                <router-link v-if="listConfig.typeOfAssessment == 'Self-Assessment'" class="text-darks hov_router" :to="{name: 'custom_search_self_assessment'}">Custom Search&nbsp;</router-link>
                <router-link v-if="listConfig.typeOfAssessment == 'Ergo Eval'" class="text-darks hov_router" :to="{name: 'custom_search_ergo_eval'}">Custom Search&nbsp;</router-link>
              </li>
              <li> / <span class="breadcrumb-item active"> View Reports </span></li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 mt-md-0 mt-2 emp_das_datepiker">
          <div class="d-flex d-md-flex align-items-center justify-content-end">
            <div class="date_toolbar_main">
              <div class="bg-white date-toolbar">
                <div class="row">
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-12 col-md-12 col-lg-6">
                        <div class="date">
                          <div class="heading_5">From</div>
                          <div
                            class="text_black font_size_14 d-flex align-items-center"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.5"
                                height="17.5"
                                viewBox="0 0 17.5 17.5"
                              >
                                <path
                                  data-name="Path 131"
                                  d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                  transform="translate(-2.25 -2.25)"
                                  style="
                                    fill: none;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 1.5px;
                                  "
                                />
                              </svg>
                            </span>
                            <datepicker
                              placeholder="MM DD, YYYY"
                              v-model="listConfig.start_date"
                              :format="customFormatter"
                              @selected="changeStartDate"
                              :disabledDates="disabledFutureDates"
                            ></datepicker>
                            <p class="min-date-value d-none">
                              {{ listConfig.start_date | formatDate }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-12 col-lg-6">
                        <div class="date todate pl_6 border-0">
                          <div class="heading_5">To</div>
                          <div
                            class="text_black font_size_14 d-flex align-items-center"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.5"
                                height="17.5"
                                viewBox="0 0 17.5 17.5"
                              >
                                <path
                                  data-name="Path 131"
                                  d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                  transform="translate(-2.25 -2.25)"
                                  style="
                                    fill: none;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 1.5px;
                                  "
                                />
                              </svg>
                            </span>
                            <datepicker
                              placeholder="MM DD, YYYY"
                              v-model="listConfig.end_date"
                              :format="customFormatter"
                              @selected="changeEndDate"
                              :disabled="isDisabled"
                              :disabledDates="disabledDates"
                            ></datepicker>
                            <p class="max-date-value d-none">
                              {{ listConfig.end_date | formatDate }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="javascript:" class="reset_btn" @click="resetDates">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000"
                xml:space="preserve"
              >
                <metadata>
                  Svg Vector Icons : http://www.onlinewebfonts.com/icon
                </metadata>
                <g>
                  <path
                    d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z"
                  ></path>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table_card">
            <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
              <div class="dataTables_length mb-3" id="datatable_length">
                <label
                  >Reports
                  <select
                    name="datatable_length"
                    aria-controls="datatable"
                    class=""
                    v-model.number="itemsPerPage"
                    @change="getReportsBysearch"
                  >
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                  entries</label
                >
              </div>
              <div id="datatable_filter" class="dataTables_filter">
                <label
                  ><input
                    type="search"
                    placeholder="Search"
                    aria-controls="datatable"
                    v-model="listConfig.search_string"
                    v-on:keyup.enter="getReportsBysearch"
                  />
                  <a
                    v-if="listConfig.search_string"
                    href="javascript:void(0)"
                    @click="getReportsBysearch"
                    class="search_icon slty_up"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      fill="#00"
                      class="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                      />
                    </svg>
                  </a>
                  <a
                    href="javascript:void(0)"
                    v-if="listConfig.search_string"
                    @click="
                      listConfig.search_string = null;
                      getReportsBysearch();
                    "
                    class="crossicon x_icon"
                  >
                    X
                  </a>
                </label>
              </div>
            </div>
            <div class="table-responsive table_audit_log">
              <table class="table table-bordered table dataTable no-footer">
                <thead>
                  <tr>
                    <th class="sort DOA">
                      <SortIcons
                        :lable="'Date Of Assessment  '"
                        :listConfig="listConfig"
                        :sortBy="'created_at'"
                        @sorting="sorting($event)"
                      />
                    </th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email Address</th>
                    <th class="sort DOA">
                      <SortIcons
                        :lable="'Type Of Assessment'"
                        :listConfig="listConfig"
                        :sortBy="'type_of_assessment'"
                        @sorting="sorting($event)"
                      />
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, indx) in reports_data" :key="indx">
                    <td>
                      <p>
                        {{
                          report.formated_date
                            ? report.formated_date
                            : report.formated_assessment_date
                        }}
                      </p>
                    </td>
                    <td>
                      <p>{{ report.first_name }}</p>
                    </td>
                    <td>
                      <p>{{ report.last_name }}</p>
                    </td>
                    <td>
                      <p>{{ report.user_email }}</p>
                    </td>
                    <td>
                      <p>
                        {{
                          report.type_of_assessment
                            ? report.type_of_assessment
                            : "Not Available"
                        }}
                      </p>
                    </td>
                    <td>
                      <div class="edit_btn">
                        <p>
                          <router-link
                            :to="{
                              name: 'custom_search_reports',
                              params: { url: report.u_id },
                            }"
                            target="_blank"
                            title="View Report"
                          >
                            <img
                              class="edit-icon"
                              :src="
                                require('@/assets/images/dashboard/View_Icon.svg')
                              "
                              alt="img"
                            />
                          </router-link>
                          &nbsp;
                          <a
                            href="#"
                            @click="showReportDataSet(report.dataset)"
                            title="Data Set"
                            data-bs-toggle="modal"
                            data-bs-target="#dataset"
                          >
                            <img
                              :src="
                                require('@/assets/images/dashboard/Database.png')
                              "
                              alt="View Dataset"
                            />
                          </a>
                          <!-- <a href="javascript:" @click="downloadZipFile(report.summary_report)" title="Download">
                            <img class="edit-icon" :src="require('@/assets/images/dashboard/download.svg')" alt="img" />
                          </a> -->
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="data_length == 0">
                    <td colspan="6" class="text-center">No Records found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="float-lg-start text-sm-center">{{ positionText }}</p>
            <VPagination
              v-if="data_length"
              class="dataTables_paginate"
              :totalPages="pages.length"
              :perPage="itemsPerPage"
              :currentPage.sync="currentPage"
              @pagechanged="onPageChange"
            />
          </div>
        </div>
      </div>
    </div>
    <DatasetModal v-bind:downloadMessage="downloadMessage" v-bind:dashboard="dashboard" v-bind:dataSet="dataSet" v-bind:isEmptyDataSet="isEmptyDataSet" ref="datasetModal"></DatasetModal>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import AuthService from "@/services/AuthService";
import $ from "jquery";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import DatasetModal from "@/components/DatasetModal";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import commonFunction from "../../../mixin/commonFunction";

export default {
  name: "my_reports",
  components: {
    Datepicker,
    VPagination,
    SortIcons,
    DatasetModal
  },
  mixins: [commonFunction],
  data() {
    return {
      downloadMessage: 'Dataset for Self-Assessment',
      dashboard: 'Custom Search',
      show: false,
      ergo_eval: false,
      reports_data: [],
      isDisabled: true,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
        typeOfAssessment: "Self-Assessment",
        userId: null,
        clientId: null,
        reportIds: [],
      },
      data_length: 0,
      dataSet: [],
      isEmptyDataSet: true,
      showDataset: false,
      previousStartDate: null,
    };
  },
  computed: {
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getSingleUserReport();
    },
  },
  // destroyed() {
  //   this.$parent.employeeDashboard = false;
  // },
  // beforeMount() {
  //   if (this.$route.params.id != "" && this.$route.params.id != undefined) {
  //       this.listConfig.userId = this.$route.params.id;
  //       this.listConfig.clientId = this.$route.params.clientId;
  //   }
  // },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    // if(this.$route.params.type == 'Ergo-Eval') {
    if (this.$route.name == "search_survey_reports_EE") {
      this.downloadMessage= 'Dataset for Ergo Eval';
      this.ergo_eval = true;
    }
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "created_at",
      order: "DESC",
      search_string: null,
      start_date: null,
      end_date: null,
      typeOfAssessment: "Self-Assessment",
      userId: null,
      clientId: null,
      reportIds: [],
      /*
        region: null,
        subregion: null,
        location: null,
        // completed_self_assessment: null,
        work_area_assessed: null,
        reason_of_assessment: null,
        discomfort_levels: null,
        cause_of_discomfort: null,
        equipment_recommendation: null,
        other_questions: null,*/
    };
    this.$parent.employeeDashboard = true;
    this.getSingleUserReport();
  },
  methods: {
    showReportDataSet(dataSet){
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = true;
        if(dataSet != null){
          var i = 1;
          for (const item in dataSet) {
            dataSet[item].id = i++;
            if(dataSet[item].options){
              for (const item1 in dataSet[item].options) {
                if(dataSet[item].options[item1].answer){
                  if(dataSet[item].answer)
                    dataSet[item].answer += ', '+dataSet[item].options[item1].answer;
                  else
                    dataSet[item].answer = dataSet[item].options[item1].answer;
                }
              }
            }
            if(dataSet[item].subquestions){
              for (const item2 in dataSet[item].subquestions) {
                if(dataSet[item].subquestions[item2].answer){
                  if(dataSet[item].answer){
                    dataSet[item].answer += ', '+dataSet[item].subquestions[item2].question+' : '+dataSet[item].subquestions[item2].answer;
                  }
                  else{
                    dataSet[item].answer = dataSet[item].subquestions[item2].question+' : '+dataSet[item].subquestions[item2].answer;
                  }
                }
              }
            }
          }
          this.dataSet = dataSet;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
          this.isEmptyDataSet = Object.keys(dataSet).length === 0;
        } else {
          this.dataSet = [];
          this.isEmptyDataSet= true;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        }
      } else {
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        $('.modal-backdrop').remove()
        $(document.body).removeClass("modal-open");
        this.tokenExpired();
      }
    },
    resetDates() {
      this.listConfig.end_date = "";
      this.listConfig.start_date = "";
      this.isDisabled = true;
      this.getSingleUserReport();
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.order == $event.order;
      this.getSingleUserReport();
    },
    getReportsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getSingleUserReport();
    },
    getSingleUserReport() {
      var _this = this;
      if (_this.$route.params.id != "" && _this.$route.params.id != undefined) {
        _this.listConfig.userId = _this.$route.params.id;
      }
      if (
        _this.$route.params.clientId != "" &&
        _this.$route.params.clientId != undefined
      ) {
        _this.listConfig.clientId = _this.$route.params.clientId;
      }

      // if(_this.listConfig.typeOfAssessment = 'Self-Assessment'){
      //   _this.listConfig.reportIds = window.localStorage.getItem("reportIds_sa"+_this.listConfig.userId);
      // } else if(_this.listConfig.typeOfAssessment = 'Ergo Eval'){
      //   _this.listConfig.reportIds = window.localStorage.getItem("reportIds_ee"+_this.listConfig.userId);
      // }

      /*if(_this.$route.params.listFilters != "" && _this.$route.params.listFilters != undefined){
          let arrFilters = JSON.parse(_this.$route.params.listFilters);
          _this.listConfig.region = arrFilters.region;
          _this.listConfig.subregion = arrFilters.subregion;
          _this.listConfig.location = arrFilters.location;
          // _this.listConfig.completed_self_assessment = arrFilters.completed_self_assessment;
          _this.listConfig.work_area_assessed = arrFilters.work_area_assessed;
          _this.listConfig.reason_of_assessment = arrFilters.reason_of_assessment;
          _this.listConfig.discomfort_levels = arrFilters.discomfort_levels;
          _this.listConfig.cause_of_discomfort = arrFilters.cause_of_discomfort;
          _this.listConfig.equipment_recommendation = arrFilters.equipment_recommendation;
          _this.listConfig.other_questions = arrFilters.other_questions;
          // console.log('_this.listConfig ::  ',_this.listConfig);
          // console.log('_this.$route.params.listFilters ::  ',JSON.parse(_this.$route.params.listFilters));
        }*/
      if (_this.$route.name != "" && _this.$route.name != undefined) {
        if (_this.$route.name == "search_survey_reports_EE") {
          _this.listConfig.typeOfAssessment = "Ergo Eval";
          _this.listConfig.reportIds = window.localStorage.getItem(
            "reportIds_ee" + _this.listConfig.userId
          );
        } else {
          _this.listConfig.typeOfAssessment = "Self-Assessment";
          _this.listConfig.reportIds = window.localStorage.getItem(
            "reportIds_sa" + _this.listConfig.userId
          );
        }
      }
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/custom-search-survey-reports",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {request_data : _this.encodeAPIRequest(_this.listConfig)},
        //data: _this.listConfig,
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.reports_data = decodedJson.records;
            _this.data_length = decodedJson.counts;
          } else {
            _this.$toast.error(data.message, {
              position: "top-right",
              duration: 5000,
            });
          }
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
          if (response.data.type == false) {
            _this.$router.push({
              name: "login",
            });
          }
        });
    },
    downloadZipFile(url) {
      AuthService.downloadZipFile(url).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Summary_Report.html");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    updateDatePicker() {
      // Extend dataTables search
      $.fn.dataTable.ext.search.push(function (settings, data) {
        var min = $(".min-date-value").html();
        var max = $(".max-date-value").html();
        var createdAt = data[0] || 0; // Our date column in the table
        if (
          min == "" ||
          max == "" ||
          (moment(createdAt).isSameOrAfter(min) &&
            moment(createdAt).isSameOrBefore(max))
        ) {
          return true;
        }
        return false;
      });
      this.getReportsBysearch();
    },
    // changeStartDate() {
    //   this.$nextTick(() => {
    //     this.isDisabled = false;
    //     this.disabledDates = {
    //       to: new Date(this.listConfig.start_date - 8640000),
    //       from: new Date(Date.now()),
    //     };
    //     if (
    //       this.listConfig.end_date != "" &&
    //       this.listConfig.end_date != null
    //     ) {
    //       this.updateDatePicker();
    //     }
    //   });
    // },
    changeStartDate(newStartDate) {
      this.$nextTick(() => {
        const startDate = new Date(newStartDate);
        const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

        if (endDate && startDate > endDate) {
          this.$toast.error('"From" date cannot be greater than the "To" date.', {
              position: "top-right",
              duration: 5000,
            });
          this.listConfig.start_date = this.previousStartDate;
        } else {
          this.previousStartDate = newStartDate;

          // Old changeStartDate code
          this.isDisabled = false;
          this.disabledDates = {
            to: new Date(this.listConfig.start_date - 8640000),
            from: new Date(Date.now()),
          };
          if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
            this.updateDatePicker();
          }
        }
      });
    },
    changeEndDate() {
      this.$nextTick(() => {
        this.updateDatePicker();
      });
    },
    customFormatter(date) {
      return moment(date).format("MMM DD, YYYY");
    },
  },
};
</script>